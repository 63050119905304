import React, { useContext, useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import { Fade } from "react-awesome-reveal";
import mobile from "is-mobile";
import { GatsbyImage } from "gatsby-plugin-image";
import { useIdle } from "react-use";
import kebabCase from "lodash.kebabcase";
import LazyLoad from "react-lazyload";
import { useAsyncFn } from "react-use";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// SEO
import { PageSeo } from "../components/seo/seo";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Context
import { PageType } from "../components/context/page-type";
import { FilterContext } from "../components/context/filter-context";

// Containers
// import { AspectRatioImageContainer } from "../components/containers/aspect-ratio-image-container";

// Components
import { Filters } from "../components/shop/filters";
import { InactivityPopUp } from "../components/popups/inactivity-popup";
import { SingleCollectionArtwork } from "../components/collection/single-collection-artwork";

const ArtworkContainer = styled.div`
  & .lazyload-wrapper {
    height: 100%;
    position: relative;
    width: 100%;
  }

  & .fade-container {
    z-index: 0;
    position: relative;
    pointer-events: none;
  }

  & .aspect-ratio-container {
    max-height: calc(${(props) => props.height}px - 250px);
    height: 100%;

    @media (max-height: 800px) {
      max-height: calc(${(props) => props.height}px - 300px);
    }

    & img {
      width: 100%;
      height: 100%;

      max-height: calc(${(props) => props.height}px - 250px);

      @media (max-height: 800px) {
        max-height: calc(${(props) => props.height}px - 300px);
      }

      object-fit: contain !important;
      object-position: left;
    }
  }

  &.overview-view {
    & a {
      width: 100%;

      & .aspect-ratio-container {
        margin: 0 auto;

        & img {
          object-position: center;
        }
      }
    }
  }
`;

const Page = styled.div`
  & .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    height: 75px;

    // transition: 249ms background-color ease;
    background-color: #fffcfa;

    z-index: 1;

    @media (max-width: 800px) {
      height: 56px;
    }
  }

  & .filters-container {
    // transition: 249ms background-color ease;
    background-color: #fffcfa;
  }

  & img {
    transition: 250ms opacity ease;
  }

  /* &.loading {
    & img {
      opacity: 0 !important;
    }
  }

  &.loaded {
    & img {
      opacity: 1;
    }
  } */

  &.active-artwork {
    & .header,
    & .filters-container {
      background-color: #e9cfc0;
    }

    /* & .artwork-container {
      opacity: 0;

      & .artwork-details,
      &.active-artwork {
        opacity: 1;
      }
    } */

    & button {
      &.active,
      &.filter.active {
        color: #658ba5 !important;
      }
    }

    & .spacer {
      & p {
        color: #658ba5;
      }
    }
  }

  & .artwork-container {
    /* transition: 250ms opacity ease; */

    & .content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
    }

    & img {
      z-index: 0;
    }

    & a {
      display: block;
      width: fit-content;
      position: relative;

      cursor: url(/icons/cursor-plus-on-white@1x.png) 12 12, pointer !important;
      cursor: -webkit-image-set(
            url(/icons/cursor-plus-on-white@1x.png) 1x,
            url(/icons/cursor-plus-on-white@2x.png) 2x
          )
          12 12,
        pointer !important;
    }

    & .artist-title {
      letter-spacing: 0.08em;
    }

    & p {
      font-size: 14px;
      line-height: 19px;

      margin: 0;

      &.artwork-title {
        margin: 15px 0 0 0;
      }
    }
  }

  @media (max-width: 500px) {
    & .artwork-container {
      & p {
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);

  &.gallery {
    grid-column-gap: 50px;

    & .artwork-details {
      transition: 250ms opacity ease;

      opacity: 0;
    }

    & .artwork-container {
      &:nth-of-type(6n + 1) {
        grid-column: 1 / 5;
        margin: 0 0 220px 0;
      }

      &:nth-of-type(6n + 2) {
        grid-column: 6 / 10;
        margin: 140px 0 220px 0;
      }

      &:nth-of-type(6n + 3) {
        grid-column: 3 / 7;
        margin: 0 0 220px 0;
      }

      &:nth-of-type(6n + 4) {
        grid-column: 1 / 4;
        margin: 0 0 220px 0;
      }

      &:nth-of-type(6n + 5) {
        grid-column: 5 / 10;
        margin: 400px 0 220px 0;
      }

      &:nth-of-type(6n + 6) {
        grid-column: 1 / 6;
        margin: 0 0 220px 0;
      }
    }
  }

  &.overview {
    grid-column-gap: 100px;
    grid-row-gap: 145px;

    & .artwork-container {
      height: 100%;
      width: 100%;

      grid-column: span 3;

      & a {
        display: grid;
        grid-template-rows: auto 4em;
        height: 100%;
        width: 100%;

        & > div {
          width: 100%;
        }
      }

      & .aspect-ratio-container {
        display: flex;
        align-items: center;
        width: 100%;

        & img {
          height: auto;
        }
      }

      & .artwork-details {
        margin: 25px 0 0 0;

        & p {
          margin: 0;
        }

        @media (max-width: 500px) {
          margin: 5px 0 0 0;
        }
      }
    }
  }

  & .sold-out {
    & .sold-out-span {
      display: block;
      margin: 10px 0 0 0;

      width: 9px;
      height: 9px;

      border-radius: 100%;
      background-color: #f1624e;

      @media (max-width: 800px) {
        margin: 3px 0 0 0;

        width: 7px;
        height: 7px;
      }
    }
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(6, 1fr);

    &.gallery {
      & .artwork-container {
        &:nth-of-type(5n + 1) {
          grid-column: 1 / 6;
          margin: 0 0 60px 0;
        }

        &:nth-of-type(5n + 2) {
          grid-column: 3 / 7;
          margin: 0 0 60px 0;
        }

        &:nth-of-type(5n + 3) {
          grid-column: 1 / 6;
          margin: 0 0 60px 0;
        }

        &:nth-of-type(5n + 4) {
          grid-column: 3 / 7;
          margin: 0 0 60px 0;
        }

        &:nth-of-type(5n + 5) {
          grid-column: 1 / 6;
          margin: 0 0 60px 0;
        }
      }
    }

    &.overview {
      grid-column-gap: 15px;
      grid-row-gap: 50px;
    }
  }

  &.no-results {
    display: block;
    grid-template-columns: unset;

    & .no-results-text {
      font-size: 16px;
      line-height: 22px;

      @media (max-width: 1440px) {
        font-size: 14px;
        line-height: 20px;
      }

      @media (max-width: 500px) {
        font-size: 9px;
        line-height: 12px;
      }
    }
  }
`;

const FiltersContainer = styled.div`
  position: sticky;
  top: 75px;

  @media (max-width: 800px) {
    top: 56px;
  }

  z-index: 100;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;

  margin: 0 0 20px 0;
  padding: 0 0 5px 0;

  & button {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.04em;

    transition: 250ms color ease;

    &.active {
      color: #dbaf96;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: #dbaf96;
      }
    }

    &.filter {
      margin: 0 35px 0 0;

      &.active {
        color: #dbaf96;
      }

      &.active.active-artwork {
        color: #dbaf96;
      }
    }

    @media (max-width: 1440px) {
      font-size: 14px;
    }
  }

  & .spacer {
    color: #dbaf96;

    & p {
      margin: 0 3px;
    }
  }

  @media (max-width: 500px) {
    & button {
      font-size: 14px;
      line-height: 17px;
    }
  }
`;

const Collection = ({ data, location }) => {
  const [pageType, setPageType] = useContext(PageType);
  const [isLoading, setIsLoading] = useState(true);

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 801px)");

  // Idle
  const isIdle = useIdle(15000); //15000
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  useEffect(() => {
    if (isIdle && isDesktop) {
      setIsPopUpOpen(true);
    }
  }, [isIdle, isDesktop]);

  // Context
  const [filterSettings, setFilterSettings] = useContext(FilterContext);

  // Is Mobile
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    setIsMobile(mobile());
  }, []);

  // Active view
  const [view, setView] = useState(`gallery`);
  const [isArtworkActive, setIsArtworkActive] = useState(false);
  const [activeArtwork, setActiveArtwork] = useState(null);

  // Image Sizing
  const height = use100vh();

  // Filters
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  useEffect(() => {
    setPageType({
      page: `shop`,
      background: `#FFFCFA`,
      banner: true,
      bannerBackground: `#BDBFB7`,
      fillColor: `#DBAF96`,
      hoverColor: `#f9e0d2`,
      underlineColor: `#f9e0d2`,
    });
  }, []);

  useEffect(() => {
    if (isFiltersOpen) {
      setPageType({
        page: `shop`,
        background: `#FFFCFA`,
        banner: true,
        bannerBackground: `#BDBFB7`,
        fillColor: `#91A8B7`,
        hoverColor: `#f9e0d2`,
        underlineColor: `#f9e0d2`,
      });
    } else {
      setPageType({
        page: `shop`,
        background: `#FFFCFA`,
        banner: true,
        bannerBackground: `#BDBFB7`,
        fillColor: `#DBAF96`,
        hoverColor: `#f9e0d2`,
        underlineColor: `#f9e0d2`,
      });
    }
  }, [isFiltersOpen]);

  useEffect(() => {
    if (
      location !== null &&
      location !== undefined &&
      location !== `undefined`
    ) {
      if (
        location.state !== null &&
        location.state !== undefined &&
        location.state !== `undefined`
      ) {
        // updateArtistFilter;
        // updatePriceFilter;
        // updateSoldItemsFilter;

        if (location.state.resetFilters !== undefined) {
          setFilterSettings({
            activeArtistFilter: [],
            activePriceFilter: null,
            hideSoldItems: false,
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [location]);

  useEffect(() => {
    if (
      location !== null &&
      location !== undefined &&
      location !== `undefined`
    ) {
      if (
        location.state !== null &&
        location.state !== undefined &&
        location.state !== `undefined`
      ) {
        if (
          location.state.activeArtistFilter !== undefined &&
          location.state.activeArtistFilter !== null
        ) {
          setFilterSettings({
            ...filterSettings,
            activeArtistFilter: [location.state.activeArtistFilter],
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [location]);

  useEffect(() => {
    if (isArtworkActive) {
      document
        .getElementById(`site-container`)
        .classList.add(`artwork-is-active`);
    } else {
      document
        .getElementById(`site-container`)
        .classList.remove(`artwork-is-active`);
    }
  }, [isArtworkActive]);

  const [productArtist, getProductArtist] = useAsyncFn(async (id) => {
    const response = await fetch(`/.netlify/functions/generate-product-url`, {
      method: "POST",
      body: JSON.stringify({
        id: id,
      }),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    });

    const result = await response.json();

    if (result !== ``) {
      return result;
    } else {
      return ``;
    }
  }, []);

  const artworks = data.prismicCollection.data.artworks
    .filter((artwork) => artwork.artwork !== null)
    .map((artwork, index) => {
      const inStock = artwork.artwork.variants
        .map((quantity) => {
          if (quantity.inventory_quantity >= 1) {
            return true;
          } else {
            return false;
          }
        })
        .indexOf(true);

      return (
        <ArtworkContainer
          height={height}
          className={`artwork-container overview-view`}
          key={`single_collection_artwork_${index}`}
        >
          <LazyLoad height={200}>
            <SingleCollectionArtwork
              artwork={artwork}
              index={index}
              key={`single_collection_artwork_${index}`}
              inStock={inStock}
              height={height}
              productArtist={productArtist}
              getProductArtist={getProductArtist}
            />
          </LazyLoad>
        </ArtworkContainer>
      );
    });

  return (
    <>
      <PageSeo
        seoTitle={`${data.prismicCollection.data.title.text} | RAW Editions`}
        seoImage={
          data.prismicCollection.data.thumbnail !== null
            ? data.prismicCollection.data.thumbnail.url
            : null
        }
        seoText={null}
      />

      <Page className={`loaded`}>
        <div className="header" />

        <Grid className={`overview`}>{artworks}</Grid>
      </Page>
    </>
  );
};

export const query = graphql`
  query ($id: String) {
    prismicCollection(id: { eq: $id }) {
      _previewable
      data {
        title {
          text
        }
        thumbnail {
          url
        }
        artworks {
          artwork {
            title
            handle
            image {
              src
              width
              height
            }
            admin_graphql_api_id
            handle
            title
            status
            variants {
              inventory_quantity
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(Collection);
