import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import kebabCase from "lodash.kebabcase";
import LazyLoad from "react-lazyload";
import { Fade } from "react-awesome-reveal";
import { useEffectOnce } from "react-use";
import { useAsyncFn } from "react-use";

// Containers

import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

export const SingleCollectionArtwork = ({
  artwork,
  index,
  inStock,
  height,
  productArtist,
  getProductArtist,
}) => {
  const [artist, setArtist] = useState(null);

  // https://stackoverflow.com/questions/56838392/how-to-call-an-async-function-inside-a-useeffect-in-react
  useEffect(() => {
    const fetchData = async () => {
      const artist = await getProductArtist(
        artwork.artwork.admin_graphql_api_id
      );
      setArtist(artist);
    };

    fetchData();
  }, []);

  if (artist === null) return null;

  return (
    <Link to={`/${kebabCase(artist)}/${artwork.artwork.handle}/`}>
      <Fade
        duration={750}
        triggerOnce={true}
        fraction={0.1}
        className="fade-container"
      >
        <div
          className="aspect-ratio-container"
          onContextMenu={(e) => e.preventDefault()}
        >
          {artwork.artwork !== null && (
            <AspectRatioImageContainer
              image={null}
              padding={
                (artwork.artwork.image.height / artwork.artwork.image.width) *
                100
              }
            >
              <img
                src={artwork.artwork.image.src}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                loading={`lazy`}
              />
            </AspectRatioImageContainer>
          )}
        </div>

        <div className="artwork-details">
          <p className="artwork-title">{artwork.artwork.title}</p>
          <p className="artist-title uppercase">
            {productArtist !== `` && productArtist !== null && <p>{artist}</p>}
          </p>
          <p className="sold-out">
            {inStock === -1 ? <span className="sold-out-span"></span> : ``}
          </p>
        </div>
      </Fade>
    </Link>
  );
};
